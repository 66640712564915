<template>
    <app-wrapper>
        <template v-slot:child>
            <CreateEntity :closeModal="() => toggleCreateEntity()" @on-created="entityCreated" />
            <CreateProduct 
                :closeModal="() => toggleCreateProduct()" 
                :transactionCategory="'PRODUCT'" 
                @on-created="productCreated" 
            />
            <div class="w-90 center">
                <div class="flex flex-wrap justify-between items-center pv3">
                    <div>
                        <p class="pb2 f3 b">
                            Purchase Order
                            <strong
                                ><font-awesome-icon
                                    icon="fa-solid fa-question-circle fa-2xs"
                                    class="text-muted clickTooltipIcon"
                                    @click="toggleTooltip = true"
                                    style="cursor: pointer"
                            /></strong>
                        </p>
                        <div class="dashboard-inquiry-modal" v-if="toggleTooltip">
                            A purchase order (PO) is a document that a buyer sends to a seller to request the purchase of goods or
                            services. It includes information about the items being purchased, the quantity, the price, the
                            delivery date, and the payment terms. The PO is a legally binding contract between the buyer and the
                            seller.
                        </div>
                    </div>
                    <router-link :to="{ name: 'PurchaseOrders' }">Back</router-link>
                </div>
                <div class="flex justify-start">
                    <div class="w-100 w-30-l pr3">
                        <h4>Order Details</h4>
                        <p>
                            Provide information about the items being purchased, the quantity, the price, the delivery date, and
                            the payment terms.
                        </p>
                    </div>
                    <div class="w-100 w-70-l">
                        <div class="flex flex-wrap justify-between">
                            <div class="flex flex-column pb3 form-spacing">
                                <label class="pb2 font-w2">Supplier Name</label>
                                <div class="flex flex-column" :style="`position: ${state.isRelative ? 'relative' : 'revert'}`">
                                    <input
                                        autocomplete="off"
                                        class="box-borderx product-inputxx form-sign"
                                        style="padding: 10px"
                                        type="text"
                                        name="entity"
                                        placeholder="Search for supplier name"
                                        id="entity"
                                        @input="element => handleEntitySearchOnChange(element.target.value)"
                                        @focusout="
                                            () => {
                                                showDropDownOnSelectEntity(false)
                                                handleIsRelative(false)
                                            }
                                        "
                                        @focusin="
                                            () => {
                                                showDropDownOnSelectEntity(true)
                                                handleIsRelative(true)
                                            }
                                        "
                                        v-model="state.supplier"
                                    />
                                    <template v-if="state.supplierDropDown && state.entity?.length">
                                        <div
                                            style="
                                            position: absolute;
                                            left: 0;
                                            top: 100%;
                                            height: auto;
                                            width: 100%;
                                            overflow: hidden;
                                            text-overflow: ellipsis;
                                            box-shadow: 0px 1px 4px 0px #2b59ff;
                                            z-index: 1000;
                                            "
                                            class="bg-white flex flex-column items-start"
                                        >
                                            <div
                                                class="mt2"
                                                style="width: 100%; max-height: 250px; overflow-y: scroll"
                                                v-if="EntitySearchedList.length"
                                            >
                                                <ul class="w-100" style="margin:0;padding:10px 0">
                                                    <li
                                                        v-for="ent of EntitySearchedList"
                                                        v-bind:key="ent._id"
                                                        class="pointer hover-bg-black-10 pa2 db"
                                                        style="width: 100%; margin:0;"
                                                        @click.prevent="handleEntityClickFromSearch(ent)"
                                                    >
                                                        <span v-if="ent?.lastName !== undefined">
                                                            {{ `${ent?.firstName} ${ent?.lastName}` || ent?.company }}
                                                        </span>

                                                        <span v-else>
                                                            {{ `${ent?.firstName}` || ent?.company }}
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div v-else class="pa3">Not found</div>
                                            <div
                                                v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(30)"
                                                class="flex items-center"
                                                style="width: 100%; gap: 0.5rem; overflow: hidden; text-overflow: ellipsis"
                                            >
                                                <button
                                                    class="mr2x pa2"
                                                    style="width: 100%; overflow: hidden; white-space: nowrap; text-overflow: ellipsis"
                                                    @click.prevent="toggleCreateEntity(true)"
                                                >
                                                    <font-awesome-icon icon="fa fa-plus-circle" /> Create a new supplier
                                                </button>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </div>
                            <div class="flex flex-column pb3 form-spacing">
                                <label class="pb2 font-w2">Delivery Outlet</label>
                                <select
                                    class="form-sign"
                                    v-model="state.deliveryOutlet"
                                    name="deliveryOutlet"
                                    id="deliveryOutlet"
                                    style="padding: 10px"
                                >
                                    <option value="">Select source business</option>
                                    <option v-for="business in state.businesses" :key="business._id" :value="business.org">
                                        {{ business.name }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="flex flex-wrap justify-between">
                            <div class="flex flex-column pb3 form-spacing">
                                <label class="pb2 font-w2">Supplier Invoice Number</label>
                                <input
                                    class="form-sign"
                                    v-model="state.reference"
                                    name="reference"
                                    id="reference"
                                    style="padding: 10px"
                                />
                            </div>
                            <div class="flex flex-column pb3 form-spacing">
                                <label class="pb2 font-w2">Order Number</label>
                                <input
                                    class="form-sign"
                                    v-model="state.number"
                                    name="reference"
                                    id="reference"
                                    style="padding: 10px"
                                />
                            </div>
                            <div class="flex flex-column pb3 form-spacing">
                                <label class="pb2 font-w2">Delivery date</label>
                                <input
                                    type="date"
                                    class="form-sign"
                                    v-model="state.date"
                                    name="date"
                                    id="date"
                                    style="padding: 10px"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <div class="flex justify-start">
                    <div class="w-100 w-30-l pr3">
                        <h4>Products</h4>
                        <p>Choose products to add to this purchase order</p>
                    </div>
                    <div class="w-100 w-70-l">
                        <div v-if="state.products.length" class="pb3">
                            <table class="table table-bordered table-striped" cellspacing="0">
                                <caption></caption>
                                <thead>
                                    <tr>
                                        <th>Product</th>
                                        <th>Current stock</th>
                                        <th>Quantity</th>
                                        <th>Cost price ({{ $store.state.Settings?.currency || 'NGN' }})</th>
                                        <th>Total price ({{ $store.state.Settings?.currency || 'NGN' }})</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(product, index) in state.products" :key="product.id">
                                        <td class="vertical-align-middle">
                                            <div>{{ product.name }}</div>
                                            <div><small>{{ product.barcode }} {{ product.sku }}</small></div>
                                        </td>
                                        <td class="vertical-align-middle">{{ product.current_stock }}</td>
                                        <td class="vertical-align-middle">
                                            <input
                                                type="number"
                                                class="product-input-2"
                                                v-model="product.quantity"
                                                @input="e => watchTransferQtyChange(e.target.value, product.current_stock, index)"
                                            />
                                        </td>
                                        <td class="vertical-align-middle">
                                            <input type="number" class="product-input-2" v-model="product.price" />
                                        </td>
                                        <td class="vertical-align-middle">
                                            {{
                                                formatAmount(
                                                    product.quantity * product.price,
                                                    $store.state.Settings?.currency || 'NGN'
                                                )
                                            }}
                                        </td>
                                        <td class="tc vertical-align-middle font-w2" width="30">
                                            <a href="" @click.prevent="removeProduct(index)" v-tippy="'Remove'">
                                                <img :src="require('@/assets/images/delete.svg')" alt="remove" />
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="input-group">
                            <span class="input-group-label">
                                <font-awesome-icon icon="fa-solid fa-search" class="fa-2x" />
                            </span>
                            <v-select
                                label="name"
                                placeholder="Search for product with Name, SKU or scan Barcode"
                                class="pv2"
                                :filterable="false"
                                :options="state.options"
                                @search="onSearchProduct"
                                @option:selected="item => selectProduct(item)"
                                @option:deselected="item => deselectProduct(item)"
                                :disabled="state.deliveryOutlet === '' || state.supplier === ''"
                            >
                                <template #open-indicator="{ attributes }">
                                    <span v-bind="attributes"></span>
                                </template>
                                <template #no-options>
                                    <em style="opacity: 0.5">Type to search product...</em>
                                </template>
                                <template slot="option" slot-scope="option">
                                    <div class="d-center">
                                        {{ option.name }}
                                    </div>
                                </template>
                                <template slot="selected-option" slot-scope="option">
                                    <div class="selected d-center">
                                        {{ option.name }}
                                    </div>
                                </template>
                                <template #list-footer="{ search }">
                                    <button class="mr2 pa1" 
                                        @click.prevent="toggleCreateProduct(true, search)"
                                        style="width:99%"
                                    >
                                        Create New Product
                                    </button>
                                </template>
                            </v-select>
                        </div>
                    </div>
                </div>
                <hr />
                <div class="flex justify-start">
                    <div class="w-100 w-30-l pr3">
                        <h4>Payments</h4>
                        <p>Add payment information to this purchase order</p>
                    </div>
                    <div class="w-100 w-70-l">
                        <div>
                            <select v-model="state.account" class="w-100" required>
                                <option value="">Select account</option>
                                <option value="Cash">Cash</option>
                                <option 
                                    v-for="(account, index) of bankAccounts" 
                                    :value="account?.aliasName || account.bankName" 
                                    :key="index"
                                >
                                    {{ account?.aliasName || account.bankName }}
                                </option>
                            </select>
                        </div>
                        <div class="flex pv3 items-center">
                            <input type="checkbox" name="credit" id="credit" v-model="state.credit" />
                            <label for="credit" class="pl2">This is a credit transaction</label>
                        </div>
                        <div v-if="state.credit" class="flex items-center pv3">
                            <div class="flex flex-column pr2 w-50">
                                <label class="pb2">Upfront payment</label>
                                <div
                                    v-tippy="{
                                        content:
                                            ' Input the amount the you paid for the product order as installments',
                                        placement: 'top',
                                        interactive: true
                                    }"
                                >
                                    <CurrencyInput
                                        class="product-input"
                                        name="initial_deposit"
                                        placeholder="0"
                                        id="initial_deposit"
                                        style="width: 100%"
                                        required
                                        v-model="state.initial_deposit"
                                        :options="numberFormat"
                                    />
                                </div>
                            </div>
                            <div class="flex flex-column w-50">
                                <label class="pb2"
                                    >{{ state.credit ? 'Balance' : 'Refund' }} Due Date<span class="required"
                                        >*</span
                                    ></label
                                >
                                <div
                                    v-tippy="{
                                        content:
                                            'Select the date you promised to pay the amount left as full payment for the order.',
                                        placement: 'top',
                                        interactive: true
                                    }"
                                >
                                    <input
                                        class="product-input"
                                        type="date"
                                        name="due_date"
                                        placeholder=""
                                        id="due_date"
                                        style="width: 100%;"
                                        required
                                        v-model="state.due_date"
                                    />
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
                <hr />
                <div class="flex justify-start">
                    <div class="w-100 w-30-l pr3">
                        <h4>Tags</h4>
                        <p>Label this purchase order for easy identification</p>
                    </div>
                    <div class="w-100 w-70-l">
                        <label>Tags</label>
                        <vue-tags-input class="mt2 w-100"
                            v-model="state.tag"
                            :tags="state.tags"
                            :validation="state.validation"
                            :autocomplete-items="state.myTags"
                            :max-tags="5"
                            placeholder="Add or select tag"
                            @tags-changed="newTags => state.tags = newTags"
                        />
                    </div>
                </div>
                <hr />
                <div class="flex justify-end items-center mt-3">
                    <button
                        v-if="state.status === 'draft'"
                        class="btn mr2"
                        @click="handlePurchaseOrder('draft')"
                        style="background-color: white; border: 1px solid rgb(19, 44, 140); color: rgb(19, 44, 140);"
                        :disabled="
                            state.isProcessing ||
                                state.deliveryOutlet === '' ||
                                state.supplier === '' ||
                                state.products.length <= 0
                        "
                    >
                        <span>{{ state.isProcessing ? 'Processing...' : 'Save as Draft' }}</span>
                    </button>
                    <button
                        @click="handlePurchaseOrder('pending')"
                        :disabled="state.isProcessing || state.deliveryOutlet === '' || state.products.length <= 0"
                    >
                        <span v-if="state.formMode === 'create'">{{ state.isProcessing ? 'Processing...' : 'Submit' }}</span>
                        <span v-else>{{ state.isProcessing ? 'Processing...' : 'Confirm' }}</span>
                    </button>
                </div>
            </div>
        </template>
    </app-wrapper>
</template>

<script>
import { onMounted, computed, reactive, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import Swal from 'sweetalert2'
import vSelect from 'vue-select'
import VueTagsInput from "@sipec/vue3-tags-input"
import AppWrapper from '@/layout/AppWrapper'
import Spinner from '@/components/Spinner'
import CreateProduct from '@/components/CreateProduct'
import CreateEntity from '@/components/CreateEntity'
import CurrencyInput from '@/components/CurrencyInput'
import ShortUniqueId from 'short-unique-id'
import moment from 'moment-timezone'
import { formatAmount } from '@/utils/lib'

export default {
    name: 'PurchaseOrderCreate',
    components: {
        AppWrapper,
        Spinner,
        CreateEntity,
        CreateProduct,
        CurrencyInput,
        vSelect,
        VueTagsInput,
    },

    data() {
        return {
            numberFormat: {
                currency: 'USD',
                currencyDisplay: 'hidden',
                hideCurrencySymbolOnFocus: true,
                hideGroupingSeparatorOnFocus: false,
                hideNegligibleDecimalDigitsOnFocus: true,
                autoDecimalDigits: false,
                useGrouping: true,
                accountingSign: true
            }
        }
    },

    setup() {
        let timeOut = null
        const toggleTooltip = ref(false)
        const store = useStore()
        const router = useRouter()
        const route = useRoute()
        const userData = computed(() => store?.state?.Auth?.userData)
        const productEntrySearchList = computed(() => store.state.Inventory?.productEntrySearchList)
        const incomingSupplierId = computed(() => store.state.Entity?.incomingEntityId)
        const EntitySearchedList = computed(() => store.state.Entity?.entityListFromSearch)
        const rolePermissions = computed(() => store?.state?.Auth?.rolePermissions)
        const role = computed(() => store?.state?.Auth?.role)
        const accountDetails = computed(() => store?.state?.Transaction?.accountDetails)
        const bankAccounts = computed(() => store.state?.Settings?.bankAccounts)

        const initialState = () => ({
            isProcessing: false,
            supplier: '',
            supplierData: {
                id: '',
                name: '',
                email: '',
                phone: '',
                address: '',
                city: '',
                state: '',
                zip: '',
                country: '',
                vat_number: '',
                tax_number: '',
                website: ''
            },
            supplierId: '',
            supplierName: '',
            deliveryOutlet: '',
            reference: new ShortUniqueId({ length: 6 }).randomUUID(),
            number: '',
            date: moment().format('YYYY-MM-DD'),
            products: [],
            businesses: [],
            status: 'draft',
            formMode: 'create',
            supplierDropDown: false,
            isRelative: false,
            account: 'Cash',
            credit: false,
            useAdvancePayment: false,
            initial_deposit: 0,
            due_date: '',
            accountDetails: [],
            tag: '',
            tags: [],
            myTags: [],
            validation: [
                {
                    classes: 'min-length',
                    rule: tag => tag.text.length < 3,
                },
                {
                    classes: 'no-numbers',
                    rule: /^([^0-9]*)$/,
                },
                {
                    classes: 'avoid-item',
                    rule: /^(?!Cannot).*$/,
                    disableAdd: true,
                },
                {
                    classes: 'no-braces',
                    rule: ({ text }) => text.indexOf('{') !== -1 || text.indexOf('}') !== -1,
                }
            ],
        })

        const state = reactive(initialState())

        const closeTooltip = () => {
            toggleTooltip.value = false
        }

        const handleEntitySearchOnChange = query => {
            state.entity = query

            clearTimeout(timeOut)

            if (query.trim().length) {
                timeOut = setTimeout(() => {
                    store.dispatch('Entity/getEntityEntryBySearch', { query })
                }, 300)
            }
        }

        const showDropDownOnSelectEntity = (dropDownState = false) => {
            if (!dropDownState) {
                return setTimeout(() => {
                    state.supplierDropDown = dropDownState
                }, 300)
            }

            state.supplierDropDown = dropDownState
        }

        const handleEntityClickFromSearch = value => {
            // console.log(value, 'value')

            if (value?.lastName || value?.lastName === undefined) {
                state.supplier = `${value?.firstName} ${value?.lastName || ''}` || value?.company
            } else {
                state.supplier = `${value?.firstName}` || value?.company
            }

            state.supplierName = value._id

            // update invoice customer
            if (value._id) {
                state.supplierData.id = value._id
                state.supplierData.name = value?.lastName ? `${value.firstName} ${value?.lastName}` : value.firstName
                state.supplierData.address = value.address1
                state.supplierData.city = value.city
                state.supplierData.state = value.state
                state.supplierData.zip = value.zip
                state.supplierData.country = value.country
                state.supplierData.phone = value.phone
                state.supplierData.email = value.email

                state.IsCustomerSelected = true
            }

            store.dispatch('Entity/setEntityIdFromExistingEntities', value._id).then(resp => {
                if (resp) onChangeSupplierId()
            })
            showDropDownOnSelectEntity(false)
        }

        const onChangeSupplierId = () => {
            state.supplierId = incomingSupplierId.value
        }

        const entityCreated = entity => {
            handleEntityClickFromSearch(entity)
        }

        const productCreated = product => {
            state.products.push({
                    name: product.name,
                    product: product._id,
                    price: product?.buyingPrice || 0,
                    current_stock: product?.qty_in_stock || 0,
                    quantity: 1,
                    instock: product?.instock || 0,
                    sku: product?.sku || '',
                    barcode: product?.barcode || '',
                    id: product._id
                })
        }

        const toggleCreateEntity = payload => {
            store.dispatch('Entity/toggleCreateEntity', payload)
            if (payload && state.entity) store.dispatch('Entity/setEntityName', state.entity)

            const body = document.querySelector('body')
            if (payload) {
                body.style.overflow = 'hidden'
            } else {
                body.style.overflow = 'auto'
            }
        }

        const handleIsRelative = (value = false) => {
            setTimeout(() => {
                state.isRelative = value
            }, 300)
        }

        watch(
            () => incomingSupplierId.value,
            (currValue, prevValue) => {
                if (currValue !== prevValue) {
                    state.supplierId = incomingSupplierId.value
                }
            }
        )

        const onSearchProduct = (search, loading) => {
            if (search.length) {
                loading(true)
                store
                    .dispatch('Production/searchProduction', { query: search, type: 'ALL', business: state.deliveryOutlet })
                    .then(data => {
                        state.options = data.products
                        loading(false)
                    })
            }
        }

        const selectProduct = item => {
            const index = state.products.findIndex((product) => product.name === item.name)
            if (index >= 0) {
                state.products[index].quantity = state.products[index].quantity + 1
            } else {
                state.products.push({
                    name: item.name,
                    product: item._id,
                    price: item?.buyingPrice || 0,
                    current_stock: item?.qty_in_stock || 0,
                    quantity: 1,
                    instock: item?.instock || 0,
                    sku: item?.sku || '',
                    barcode: item?.barcode || '',
                    id: item._id
                })
            }
            
            state.options = []
            item.name = 'Search for product with Name, SKU or scan Barcode'
        }

        const deselectProduct = (product, item) => {}

        const toggleCreateProduct = (payload, data) => {
            store.dispatch('Inventory/showCreateProduct', { show: payload, cat: 'PRODUCT' })
            if (payload && data) {
                store.dispatch('Inventory/showIncomingProductName', data)
            }
            store.dispatch('Inventory/resetProductEntryBySearch', [])
        }

        const resetState = () => {
            state.reference = new ShortUniqueId({ length: 6 }).randomUUID()
            state.date = moment().format('YYYY-MM-DD')
            state.products = []
            state.options = []
        }

        watch(accountDetails, newValue => {
            if (newValue) {
                state.data = Object.values(newValue)?.filter(value => value.type !== null && parseInt(value.balance) !== 0)
            }
        })

        const watchTransferQtyChange = (value, max, index) => {
            if (value > max) {
                // state.products[index].quantity = max
            }
        }

        const removeProduct = index => {
            const { products } = state
            const productToDelete = products.splice(index, 1)
            state.products = products.filter(element => element !== productToDelete[0])
        }

        const handlePurchaseOrder = (status = 'draft') => {
            if (state.account === '' && !state.credit && state.initial_deposit === 0) {
                Swal.fire({
                    title: 'Select Account',
                    text: 'Please select a payment type or choose credit if transaction is a credit purchase',
                })
                return false
            }
            Swal.fire({
                    title: status === 'draft' ? `Confirm Save as Draft` : `Confirm Purchase Order`,
                    text: status === 'draft' ? `Are you sure you want to save this purchase order as draft?` : `Are you sure you want to submit this purchase order?`,
                    showDenyButton: true,
                    showCancelButton: false,
                    confirmButtonText: 'Yes, Continue!',
                    denyButtonText: `No, Cancel!`
                }).then(result => {
                    if (result.isConfirmed) {
                        state.isProcessing = true
                        const deliveryOutlet = state.businesses.filter(business => business.org === state.deliveryOutlet)[0]
                        const data = {
                            supplier: state.supplierData,
                            deliveryOutlet: { id: deliveryOutlet.org, name: deliveryOutlet.name, email: deliveryOutlet.orgEmail },
                            number: state.number,
                            reference: state.reference,
                            date: state.date,
                            products: state.products,
                            credit: state.credit,
                            initial_deposit: state.initial_deposit,
                            due_date: state.due_date,
                            status: status,
                        }

                        data.totalItems = state.products.reduce((prevVal, currValue) => {
                            prevVal += parseFloat(currValue.quantity)
                            return prevVal
                        }, 0)

                        data.totalValue  = state.products.reduce((prevVal, currValue) => {
                            prevVal += parseFloat(currValue.price) * parseFloat(currValue.quantity)
                            return prevVal
                        }, 0)

                        data.glAccounts = [{ glAccount: state.account, glAmount: data.totalValue }]

                        data.tags = state.tags.map(tag => {
                            return tag.text
                        })

                        if (state.formMode === 'create') {
                            store.dispatch(`Inventory/createPurchaseOrder`, data)
                                .finally(() => {
                                    resetState()
                                    state.isProcessing = false
                                    router.push({ name: 'PurchaseOrders' })
                                })
                        } else {
                            const { id } = route.query
                            store.dispatch(`Inventory/updatePurchaseOrder`, { id, data })
                                .finally(() => {
                                    router.push({ name: 'PurchaseOrders' })
                                    state.isProcessing = false
                                })
                        }
                                    
                    }
                })
        }

        const getPurchaseOrder = id => {
            store.dispatch('Inventory/getPurchaseOrder', { id }).then(resp => {
                const { status, data } = resp
                if (status) {
                    state.supplierName = data?.supplier?.name || ''
                    state.supplier = data?.supplier?.name || ''
                    state.supplierId = data?.supplier?.id || ''
                    state.supplierData = data?.supplier
                    state.deliveryOutlet = data?.deliveryOutlet?.id || ''
                    state.reference = data?.reference
                    state.number = data?.number
                    state.date = data?.date
                    state.products = data?.products
                    state.account = data?.glAccounts[0].glAccount || ''
                    state.credit = data?.credit || false
                    state.initial_deposit = data?.initial_deposit || 0
                    state.due_date = data?.due_date || ''
                    state.status = data?.status
                    state.formMode = 'edit'
                }
            })
        }

        onMounted(() => {
            store.dispatch('Settings/getBankAccounts')
            store.dispatch('Transaction/getAccountDetails').then(data => {
                state.accountDetails = Object.values(data)?.filter(value => value.type !== null && parseInt(value.balance) !== 0)
            })
            store
                .dispatch('Settings/getBusinesses', true)
                .then(resp => {
                    if (resp.status) {
                        state.businesses = resp.data?.length ? resp.data : [{
                            org: userData.value.orgId,
                            name: userData.value.org,
                            orgEmail: userData.value.email,
                        }]
                        state.deliveryOutlet = userData.value?.orgId || ''
                    }
                })
                .finally(() => {
                    const { id } = route.query
                    if (id) {
                        getPurchaseOrder(id)
                    }
                })
            
            store.dispatch('Inventory/getPurchaseOrderNumber').then(resp => {
                if (resp.status) {
                    state.number = resp.data
                }
            })
            
            store.dispatch('Tag/getTags').then(data => {
                if (data.status) {
                    state.myTags = data?.data || []
                }
            })

            window.addEventListener('click', event => {
                const tooltipIcon = document.querySelector('.clickTooltipIcon')

                if (
                    !(event.target.closest('.clickTooltipIcon') || event.target === tooltipIcon) &&
                    toggleTooltip.value === true
                ) {
                    closeTooltip()
                }
            })
        })

        return {
            state,
            userData,
            role,
            rolePermissions,
            toggleTooltip,
            onSearchProduct,
            selectProduct,
            deselectProduct,
            handlePurchaseOrder,
            productEntrySearchList,
            formatAmount,
            watchTransferQtyChange,
            removeProduct,
            handleEntitySearchOnChange,
            showDropDownOnSelectEntity,
            handleEntityClickFromSearch,
            EntitySearchedList,
            entityCreated,
            productCreated,
            toggleCreateEntity,
            handleIsRelative,
            toggleCreateProduct,
            bankAccounts,
        }
    }
}
</script>

<style>
.vs__selected-options {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 30px;
}
.input-group {
    position: relative;
}
.input-group-label {
    position: absolute;
    width: 10px;
    top: 23px;
    left: 15px;
    cursor: pointer;
    z-index: 10;
}
.fa-2x {
    font-size: 1.3em;
}
.vue-tags-input {
    max-width: 100% !important;
  }
</style>
